require("./plugins/bootstrap.js");
require("./plugins/dropdown.js");
require("./plugins/magnific-popup.js");
require("./plugins/slick.js");

require("./modules/forms.js");
require("./modules/mobile-nav.js");

if ($('#maps').length){
require("./modules/location.js");
}
