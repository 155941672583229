(function($) {
    (function() {

        var toggle = document.querySelector('.js-nav-toggle');
        var menu = document.querySelector('.js-mnav-box');
        var header = document.querySelector('.js-header');

        function setMenuDimensions() {
            var top = header.offsetHeight;
            menu.style.paddingTop = top + 'px';
            menu.style.height = window.innerHeight + 'px';
        };

        function toggleMenu(e) {
            e.preventDefault();
            menu.classList.toggle('isOpen');
            this.classList.toggle('isOpen');
            document.documentElement.classList.toggle('isFixed');
            header.classList.toggle('isOpen');
        };

        setMenuDimensions();
        toggle.addEventListener('click', toggleMenu);
        window.addEventListener('resize', setMenuDimensions);

    })();

    (function() {

        var subnav = document.querySelectorAll('.mnav .menu_level_1');
        var subnavArr = Array.prototype.slice.call(subnav);

        subnavArr.forEach(function(item) {
            var toggle = document.createElement('span');
            toggle.classList.add('js-msubnav-toggle');
            toggle.addEventListener('click', function() {
                this.parentNode.querySelector('.menu_level_1').classList.toggle('isOpen');
                this.classList.toggle('isOpen');
            });
            var parent = item.parentNode;
            parent.appendChild(toggle);
        });

    })();

    (function() {

        var subnav = document.querySelectorAll('.mnav .menu_level_2');
        var subnavArr = Array.prototype.slice.call(subnav);

        subnavArr.forEach(function(item) {
            var toggle = document.createElement('span');
            toggle.classList.add('js-msubnav-toggle');
            toggle.addEventListener('click', function() {
                this.parentNode.querySelector('.menu_level_2').classList.toggle('isOpen');
                this.classList.toggle('isOpen');
            });
            var parent = item.parentNode;
            parent.appendChild(toggle);
        });

    })();

})(jQuery);
