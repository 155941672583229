(function () {
    var GoogleMapsLoader = require('google-maps');

    GoogleMapsLoader.load(function (google) {

        var map;

        function initialize() {
            var glat = 51.2459159;
                glong = 3.3427691;

            var mapOptions = {
                zoom: 16,
                scrollwheel: false,
                center: new google.maps.LatLng(glat, glong)
            };
            map = new google.maps.Map(document.getElementById('maps'), mapOptions);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(glat, glong),
                map: map
            });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
    });
})();
